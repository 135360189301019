<template>
  <main>
	 <div id="app">
		<div id="wrapper">
		  <Navigation v-if="navigationShow" :highlightSupport="highlightSupport"/>
		  <div id="content-wrapper" class="d-flex flex-column" :class="isLogin ? 'content-wrapper-nav-fixed': ''">
			 <div id="content" class="mobile">
				<Head v-if="navigationShow" @support-clicked="supportClicked"/>
				<div class="container-fluid">
				  <router-view/>
				</div>
			 </div>
		  </div>
		</div>
	 </div>
  </main>
</template>

<style scoped>
#content{
  background : #f8f8f8;
}
</style>

<script>
import { viewLogger } from '@/lib/utils'
import { User } from '@/services/User'

export default {
  data() {
	 return {
		updateID: null,
		updatePeriod: 20000,
		disableHighlightPeriod: 3000,
		user: null, // Данные пользователя
		highlightSupport: false // Подсветка поддержки
	 }
  },
  components: {
	 // Ленивая загрузка компонентов Navigation и Head
	 Navigation: () => import('./components/Nav'),
	 Head: () => import('./components/Header')
  },
  computed: {
	 // Активный пользователь
	 userActive() {
		return this.$root.user
	 },
	 // Показывать ли навигацию
	 navigationShow() {
		// Проверяем, есть ли meta и не является ли маршрут "blank"
		return this.userActive && !( this.$route.meta && this.$route.meta.blank )
	 },
   isLogin(){
     return !!this.user
   }
  },
  methods: {
	 // Метод для обработки клика по поддержке
	 supportClicked() {
		this.highlightSupport = true
		// Отключаем подсветку через 3 секунды
		setTimeout( () => {
		  this.highlightSupport = false
		}, this.disableHighlightPeriod )
	 },
	 // Метод для обновления данных пользователя
	 updateUserData() {
		if ( this.$root.user ) {
		  User.get( { id: this.$root.user.id }, { cache: false } ).then( user => user && ( this.$root.user = user ) )
		}
	 }
  },
  mounted() {
   // Периодическое обновление данных пользователя
	 this.updateUserData()
	 this.updateID = setInterval( this.updateUserData, this.updatePeriod ) // Интервал обновления каждые 20 секунд
	 
	 // Обработка события установки пользователя
	 this.$root.$on( 'user', user => this.user = user )
	 
	 // Обработка события выхода пользователя
	 this.$root.$on( 'user:logout', () => {
		this.user = null
		User.clear()
	 } )
	 
	 // Получение данных пользователя из localStorage при загрузке
	 const user = User.getLocalUser()
	 if ( user ) {
		this.$root.$emit( 'user', user )
	 }
  },
  destroyed() { // Остановка обновления данных пользователя
	 clearInterval( this.updateID )
  },
  beforeMount() {
	 viewLogger( true, 'App', this )
  },
  beforeDestroy() {
	 viewLogger( false, 'App', this )
  }
}
</script>
